import { Box, Typography, Chip, Tooltip, useTheme, Grid, Paper } from '@material-ui/core'
import { 
  components, 
  constants, 
  useServices, 
  useTranslation 
} from 'cng-web-lib'
import moment from 'moment-timezone'
import { useParams } from 'react-router-dom'

import EhblManifestApiUrls from 'src/apiUrls/EhblManifestApiUrls'
import React, { useEffect, useRef, useState } from 'react'
import EhblManifestKeys from 'src/constants/locale/key/EhblManifest'
import Namespace from 'src/constants/locale/Namespace'
import Table from '../../../components/aciacehighway/Table'
import TinyChip from '../../../components/aciacehighway/TinyChip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FileForUserGetPartyId, FileForUserGetUserDetails,GetFilterByUserIdCriteria } from 'src/common/FileForUserCommon'
import pathMap from 'src/paths/pathMap'
import NaCodeMaintenanceApiURLs from 'src/apiUrls/NaCodeMaintenanceApiUrls'
import { getStatusMetadata } from '../../../common/NACommon'
import ConfirmDialog from '../../common/ConfirmDialog'
import ManifestCSVUploadDialog from './upload/ManifestCSVUploadDialog'


const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    DateRangeFilter: CngDateRangeFilter,
    useDefaultNotification,
    useFetchCodeMaintenanceLookup
  }
} = components

const {
  filter: { BETWEEN, EQUAL, IN, LIKE }
} = constants

const {
  locale: {
    key: { UiComponentKeys }
  }
} = constants

function TablePage(props) {

  const { history, location, manifestId, showNotification } = props
  const { id } = useParams()
  const tableRef = useRef(null)
  const { createRecord, deleteRecord, fetchRecords, securedSendRequest } = useServices()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

  const notification = useDefaultNotification(showNotification)
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.EHBL_MANIFEST
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  const [lookups, setLookups] = useState(null)
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    manifest: null
  })

  const [uploadCSVManifestDialog, setUploadCSVManifestDialog] = useState({ open: false, tableRef: null });
  
  
  useEffect(() => {
    Promise.all([
      // Status
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'EHBL_HB_STATUS' }],
        undefined,
        'code'
      ),
      fetchRecords.execute(
        NaCodeMaintenanceApiURLs.GET,
        { 
          filters: [{ field: 'indicator', operator: EQUAL, value: 'HBL' }],
          customData: { codeMType: 'CustomsOffice' } 
        },
        (res) => {
          const result = {}

          res.content.forEach((item) => {
            result[item.code] = item.descriptionEn
          })

          return result
        }
      )
    ]).then(([status,portCode]) => {
      console.log(status)
      let statusCode = []
      if(status!=null){
        Object.keys(status).forEach((key) => {
          let statusCodeObject = {}
          statusCodeObject.label = status[key]
          statusCodeObject.value = status[key]
          statusCodeObject.filterValue = {
            "value" : key
          }
          statusCode.push(statusCodeObject)
        });
      }
      setLookups({ status,portCode,statusCode})
    })
  }, []);

  function handleDeleteManifest() {
    if (confirmDialog.manifest) {
      deleteRecord.execute(
        EhblManifestApiUrls.DELETE,
        confirmDialog.manifest,
        () => {
          showNotification('success', 'Delete succeeded')
          setConfirmDialog({ open: false, manifest: null })
          tableRef.current.performRefresh()
        },
        (error) => {
          console.log(error)
        }
      )
    }
  }

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  let filter = GetFilterByUserIdCriteria("EHBL")

  function makeTranslatedTextsObject() {
    let ehblManifest = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TITLE
    )
    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      {
        nameTitleised: ehblManifest
      }
    )
    let corpid = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.CORPID
    )
    let movementType = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.MOVEMENT_TYPE
    )
    let indicatorMode = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.INDICATOR_MODE
    )
    let primaryCcn = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.PRIMARY_CCN
    )
    let carrierCode = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.CARRIER_CODE
    )
    let portOfDischargeCode = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.PORT_OF_DISCHARGE_CODE
    )
    let portOfDestCode = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.PORT_OF_DEST_CODE
    )
    let messageFuncCode = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.MESSAGE_FUNC_CODE
    )
    let consolidationInd = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.CONSOLIDATION_IND
    )
    let totalWeight = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TOTAL_WEIGHT
    )
    let status = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.STATUS
    )
    let closeStatus = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.CLOSE_STATUS
    )
    let responseDatetime = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.RESPONSE_DATETIME
    )
    let serviceBureauId = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.SERVICE_BUREAU_ID
    )
    let userRole = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.USER_ROLE
    )
    let email = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.EMAIL
    )
    let mobileNo = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.MOBILE_NO
    )
    let totalWeightUom = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TOTAL_WEIGHT_UOM
    )
    let myReferenceNo = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.MY_REFERENCE_NO
    )
    let destSubLocCode = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.DEST_SUB_LOC_CODE
    )
    let discSubLocCode = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.DISC_SUB_LOC_CODE
    )
    let clStatus = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.CL_STATUS
    )
    let clMsgFuncCode = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.CL_MSG_FUNC_CODE
    )
    let clAmendCode = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.CL_AMEND_CODE
    )
    let clUniqueRefNo = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.CL_UNIQUE_REF_NO
    )
    let containerizedInd = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.CONTAINERIZED_IND
    )
    let totalVolume = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TOTAL_VOLUME
    )
    let totalVolumeUom = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TOTAL_VOLUME_UOM
    )
    let cargoQuantity = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.CARGO_QUANTITY
    )
    let quantityUom = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.QUANTITY_UOM
    )
    let b2bComment = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.B2B_COMMENT
    )
    let totalHbl = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.TOTAL_HBL
    )

    let createdBy = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.CREATED_BY
    )
    let createdDate = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.CREATED_DATE
    )

    let updatedBy = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.UPDATED_BY
    )
    let updatedDate = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.UPDATED_DATE
    )

    let submittedBy = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.SUBMITTED_BY
    )
    let submittedDate = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.SUBMITTED_DATE
    )

    let editButton = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.EDIT_BUTTON
    )

    let editSentRecord = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.EDIT_SENT_RECORD
    )

    let cloneButton = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.CLONE_BUTTON
    )

    let deleteButton = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.DELETE_BUTTON
    )

    let deleteNotDraftRecord = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.DELETE_NOT_DRAFT_RECORD
    )

    let uploadCSVButton = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.UPLOAD_CSV_BUTTON
    )

    let createManifestButton = translate(
      Namespace.EHBL_MANIFEST,
      EhblManifestKeys.CREATE_MANIFEST_BUTTON
    )
    

    return {
      ehblManifest,
      tableTitle,
      corpid,
      movementType,
      indicatorMode,
      primaryCcn,
      carrierCode,
      portOfDischargeCode,
      portOfDestCode,
      messageFuncCode,
      consolidationInd,
      totalWeight,
      status,
      closeStatus,
      responseDatetime,
      serviceBureauId,
      userRole,
      email,
      mobileNo,
      totalWeightUom,
      myReferenceNo,
      destSubLocCode,
      discSubLocCode,
      clStatus,
      clMsgFuncCode,
      clAmendCode,
      clUniqueRefNo,
      containerizedInd,
      totalVolume,
      totalVolumeUom,
      cargoQuantity,
      quantityUom,
      b2bComment,
      totalHbl,
      createdBy,
      createdDate,
      updatedBy,
      updatedDate,
      submittedBy,
      submittedDate,
      editButton,
      editSentRecord,
      cloneButton,
      deleteButton,
      deleteNotDraftRecord,
      uploadCSVButton,
      createManifestButton
    }
  }

  const columns = [
    {
      field: "primaryCcn",
      sortKey: "primaryCcn",
      title: translatedTextsObject.primaryCcn,
    },
    {
      field: "portOfDischargeCode",
      sortKey: "portOfDischargeCode",
      title: translatedTextsObject.portOfDischargeCode,
      render: (data) => {
        
        if(data.portOfDischargeCode){
          return(
            <Typography component='div' variant='inherit'>
              {getLookupValue('portCode', data.portOfDischargeCode)}
              <TinyChip label={data.portOfDischargeCode} variant='outlined' />
            </Typography>
          )
        }else{
          return ""
        }
      }
    },
    {
      field: "portOfDestCode",
      sortKey: "portOfDestCode",
      title: translatedTextsObject.portOfDestCode,
      render: (data) =>
      <Typography component='div' variant='inherit'>
        {getLookupValue('portCode', data.portOfDestCode)}
        <TinyChip label={data.portOfDestCode} variant='outlined' />
      </Typography>
    },
    {
      field: "status",
      sortKey: "status",
      title: translatedTextsObject.status,
      render: (data) => {

        let status = getStatusMetadata(data.status === 'AC' ? '1000' : data.status)

        let content = [
          <Box>
            <Typography variant='inherit' style={{ color: status.color }}>
              {status.label}
            </Typography>
            <Typography color='textSecondary' style={{ fontSize: 12, lineHeight: 1.2 }}>
              {data.responseDatetime && moment(data.responseDatetime).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')}
            </Typography>
          </Box>
        ]

        return (
          <Box display='flex' flexDirection='row' flexWrap='wrap' style={{ gap: 4 }}>
            {content}
          </Box>
        )
      }
    },{
      field: "createdDate",
      sortKey: "createdDate",
      title: translatedTextsObject.createdDate,
      render: (data) =>
        data.createdDate && moment(data.createdDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    },
    {
      field: "submittedDate",
      sortKey: "submittedDate",
      title: translatedTextsObject.submittedDate,
      render: (data) =>
      data.submittedDate && moment(data.submittedDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    },
    {
      field: "responseDatetime",
      sortKey: "responseDatetime",
      title: translatedTextsObject.responseDatetime,
      render: (data) =>
      data.responseDatetime && moment(data.responseDatetime).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    },
    {
      field: "myReferenceNo",
      sortKey: "myReferenceNo",
      title: translatedTextsObject.myReferenceNo,
    },
    /* {
       field: "corpid",
       title: translatedTextsObject.corpid,
     },
     {
       field: "movementType",
       title: translatedTextsObject.movementType,
     },
     {
       field: "indicatorMode",
       title: translatedTextsObject.indicatorMode,
     },
    
    {
      field: "carrierCode",
      title: translatedTextsObject.carrierCode,
    },
    {
      field: "messageFuncCode",
      title: translatedTextsObject.messageFuncCode,
    },
    {
      field: "consolidationInd",
      title: translatedTextsObject.consolidationInd,
    },
    {
      field: "totalWeight",
      title: translatedTextsObject.totalWeight,
    },
    {
      field: "closeStatus",
      title: translatedTextsObject.closeStatus,
    },
    {
      field: "serviceBureauId",
      title: translatedTextsObject.serviceBureauId,
    },
    {
      field: "userRole",
      title: translatedTextsObject.userRole,
    },
    {
      field: "email",
      title: translatedTextsObject.email,
    },
    {
      field: "mobileNo",
      title: translatedTextsObject.mobileNo,
    },
    {
      field: "totalWeightUom",
      title: translatedTextsObject.totalWeightUom,
    },
    {
      field: "destSubLocCode",
      title: translatedTextsObject.destSubLocCode,
    },
    {
      field: "discSubLocCode",
      title: translatedTextsObject.discSubLocCode,
    },
    {
      field: "clStatus",
      title: translatedTextsObject.clStatus,
    },
    {
      field: "clMsgFuncCode",
      title: translatedTextsObject.clMsgFuncCode,
    },
    {
      field: "clAmendCode",
      title: translatedTextsObject.clAmendCode,
    },
    {
      field: "clUniqueRefNo",
      title: translatedTextsObject.clUniqueRefNo,
    },
    {
      field: "containerizedInd",
      title: translatedTextsObject.containerizedInd,
    },
    {
      field: "totalVolume",
      title: translatedTextsObject.totalVolume,
    },
    {
      field: "totalVolumeUom",
      title: translatedTextsObject.totalVolumeUom,
    },
    {
      field: "cargoQuantity",
      title: translatedTextsObject.cargoQuantity,
    },
    {
      field: "quantityUom",
      title: translatedTextsObject.quantityUom,
    },
    {
      field: "b2bComment",
      title: translatedTextsObject.b2bComment,
    },
    {
      field: "totalHbl",
      title: translatedTextsObject.totalHbl,
    }*/
  ]

  let statusCode = [
    {
      label: 'Draft',
      value: 'draft',
      filterValue: {
        value: 'DR'
      }
    },
    {
      label: 'Accepted',
      value: 'accepted',
      filterValue: {
        value: 'AC'
      }
    },
    {
      label: 'Sent',
      value: 'sent',
      filterValue: {
        value: 'ST'
      }
    },
    {
      label: 'Rejected',
      value: 'rejected',
      filterValue: {
        value: 'RJ'
      }
    },
    /*{
      label: 'Template',
      value: 'Template',
      filterValue: {
        value: 'TP'
      }
    },*/
    {
      label: 'Status Notification',
      value: 'status_notification',
      filterValue: {
        value: 'SN'
      }
    },
    {
      label: 'Cancellation Accepted',
      value: 'cancellation_accepted',
      filterValue: {
        value: 'CL'
      }
    },
    {
      label: 'Cancellation Rejected',
      value: 'cancellation_rejected',
      filterValue: {
        value: 'CR'
      }
    },
    {
      label: 'Modification Rejected',
      value: 'modification_rejected',
      filterValue: {
        value: 'MR'
      }
    }
  ]
  const filters = [
     {
      name: "primaryCcn",
      label: translatedTextsObject.primaryCcn,
      type: 'textfield',
      operatorType: LIKE
    },
    {
      name: "portOfDischargeCode",
      label: translatedTextsObject.portOfDischargeCode,
      type: 'textfield',
      operatorType: LIKE
    },
    {
      name: "portOfDestCode",
      label: translatedTextsObject.portOfDestCode,
      type: 'textfield',
      operatorType: LIKE
    },
    {
      name: "status",
      label: translatedTextsObject.status,
      type: 'checkbox',
      operatorType: IN,
      options: statusCode
    },
    {
      name: "createdDate",
      label: translatedTextsObject.createdDate,
      type: 'daterange',
      operatorType: LIKE
    },
    {
      name: "submittedDate",
      label: translatedTextsObject.submittedDate,
      type: 'daterange',
      operatorType: LIKE
    },
    {
      name: "submittedDate",
      label: translatedTextsObject.submittedDate,
      type: 'daterange',
      operatorType: LIKE
    },
    {
      name: "responseDatetime",
      label: translatedTextsObject.responseDatetime,
      type: "daterange",
      operatorType: LIKE
    },
    {
      name: "myReferenceNo",
      label: translatedTextsObject.myReferenceNo,
      type: 'textfield',
      operatorType: LIKE
    }
  ]

  return (
    <>
      <Table
        actions={[
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
              onClick: () => setUploadCSVManifestDialog({ open: true, tableRef: tableRef })
            },
            label: translatedTextsObject.uploadCSVButton
          },
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
              onClick: () => history.push(pathMap.EHBL_MANIFEST_ADD_VIEW)
            },
            label: translatedTextsObject.createManifestButton
          }
        ]}
        columns={columns}
        compact
        exportData={{ url: EhblManifestApiUrls.EXPORT }}
        fetch={{ url: EhblManifestApiUrls.GET }}
        fetchFilters={[
          { 
            field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId()
          },
          ...filter 
        ]}
        filters={filters}
        onRowClick={(rowData) => {
          if (rowData.status === 'ST') {
            history.push(`${location.pathname}/view/${rowData.id}`)
          }
          else {
            history.push(`${location.pathname}/edit/${rowData.id}`)
          }
        }
        }
        rowActions={[
          {
            disabled: (rowData) => rowData.status === 'ST',
            icon: <FontAwesomeIcon icon={['fal', 'pen']} />,
            label: translatedTextsObject.editButton,
            onClick: (rowData) =>
              history.push(`${location.pathname}/edit/${rowData.id}`),
            tooltip: (rowData) =>
              rowData.status === 'ST'
                ? translatedTextsObject.editSentRecord
                : null
          },
          {
            disabled: (data) => data.status !== 'DR',
            label: translatedTextsObject.deleteButton,
            icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
            onClick: (data) => setConfirmDialog({ open: true, manifest: data }),
            tooltip: (rowData) =>
            rowData.status !== 'DR'
              ? translatedTextsObject.deleteNotDraftRecord
              : null
          }
        ]}
        showNotification={showNotification}
        sortConfig={{
          type: 'column',
          defaultField: 'createdDate',
          defaultDirection: 'DESC'
        }}
        persistSettings
        tableRef={tableRef}
        checkboxSelection
        selectActions={[
          /*{
            disabled: (rows) => rows.length > 100,
            icon: <FontAwesomeIcon icon={['fal', 'link']} />,
            label: "Export Manifest(s)",
            onClick: (rows) => {
              sweetalert("Exporting Manifest in the background. The report will be downloaded automatically once ready.", "", "success");
              return exportManifest(rows);
            },
            tooltip: (rows) => rows.length > 100 ? "Maximum of 100 records per export" : null

          }*/
        ]}
      />
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialog.open}
        closeDialog={() => setConfirmDialog({ open: false, manifest: null })}
        confirmDialog={handleDeleteManifest}
        content="Items that you delete can't be restored. Are you sure about this?"
        okMsg='Yes, delete'
        cancelMsg='No, take me back'
        title={translatedTextsObject.deleteButton}
      />
      <ManifestCSVUploadDialog
        open={uploadCSVManifestDialog}
        onClose={() => setUploadCSVManifestDialog({ open: false, tableRef: null })}
        showNotification={showNotification}
      />
    </>
  )
}

export default TablePage
